<template>
  <header class="!h-16 flex items-center justify-between">
    <div></div>
    <div class="flex items-center gap-3">
      <div class="flex gap-2 items-center">
        <Typography
          as="label"
          variant="smMedium"
          color="text-gray500"
        >
          {{ $t('sandboxMode') }}
        </Typography>
        <NewSwitch
          :model-value="newGlobal.mode"
          size="large"
          color="primary600"
          :label="$t('sandboxMode')"
          @update:model-value="toggleMode"
        />
      </div>

      <Dropdown
        popper-class="-mt-1"
        class="!hidden"
        trigger="click"
        @command="handleCommand"
        @visible-change="toggleDropdown"
      >
        <button class="flex gap-1.5 items-center">
          <MaIcon
            name="world"
            class="h-6 w-6"
          />
          <Typography
            variant="baseBold"
            class="uppercase"
            >{{ locale }}</Typography
          >
          <icon
            name="downArrow"
            :class="['w-5 !text-secondary duration-300', isOpen ? 'rotate-180' : '']"
          />
        </button>
        <template #dropdown>
          <DropdownItem
            v-for="item in locales"
            :key="item.code"
            :command="item.code"
          >
            {{ item.name }}
          </DropdownItem>
        </template>
      </Dropdown>

      <Button
        size="medium"
        class="!px-2"
      >
        <MaIcon
          name="add"
          class="text-gray900 w-5"
        />
      </Button>
      <Dropdown
        popper-class="-mt-1"
        trigger="click"
        size="large"
        placement="bottom-end"
        @command="handleSettingsCommand"
      >
        <button class="flex items-center gap-2.5">
          <avatar
            :name="business?.name"
            :src="business.logo"
            :size="40"
            bg-color="bg-primary100"
            color="text-gray900"
          />
        </button>
        <template #dropdown>
          <div class="flex py-2.5 px-3 gap-3 pr-4">
            <avatar
              :name="business?.name"
              :src="business.logo"
              :size="40"
              bg-color="bg-primary100"
              color="text-gray900"
            />

            <div>
              <Typography color="text-gray900">{{ `${user?.firstName} ${user?.lastName}` }} </Typography>
              <Typography color="text-gray800">{{ user?.email }} </Typography>
            </div>
          </div>
          <DropdownItem
            divided
            :command="'profile'"
          >
            <span class="py-1">
              {{ $t('profile') }}
            </span>
          </DropdownItem>
          <DropdownItem :command="'notifications'">
            <span class="py-1">
              {{ $t('Notifications') }}
            </span>
          </DropdownItem>
          <DropdownItem
            divided
            :command="'team'"
          >
            <span class="py-1">
              {{ $t('settings.team') }}
            </span>
          </DropdownItem>
          <DropdownItem :command="'invite-colleagues'">
            <span class="py-1">
              {{ $t('settings.Invite colleagues') }}
            </span>
          </DropdownItem>
          <DropdownItem
            divided
            :command="'generate-payment'"
          >
            <span class="py-1">
              {{ $t('settings.Changelog') }}
            </span>
          </DropdownItem>
          <DropdownItem
            v-if="checkoutIsEnabled"
            :command="'generate-payment'"
          >
            <span class="py-1">
              {{ $t('overview.generatePayment') }}
            </span>
          </DropdownItem>
          <DropdownItem :command="'support'">
            <span class="py-1">
              {{ $t('overview.support') }}
            </span>
          </DropdownItem>
          <DropdownItem :command="'developer'">
            <span class="py-1"> API </span>
          </DropdownItem>
          <DropdownItem
            divided
            command="sign-out"
          >
            <Typography
              color="text-red"
              class="py-1 min-w-[240px]"
              >{{ $t('overview.logOut') }}
            </Typography>
          </DropdownItem>
        </template>
      </Dropdown>
    </div>
  </header>
</template>

<script lang="ts" setup>
import { MaIcon } from '@wirepay/maplerad-component-lib'
import { useBusiness } from '~/src/composables/apis/business'
import { useUser } from '~/src/composables/apis/user'
import { createAccount } from '~/src/composables/apis/account'
import { useTransactionsModal, useSettingsModal } from '~/src/composables/core/modals'
import { useFeatureToggle, FeatureToggles } from '~/src/composables/utils/useFeatureToggle'

const router = useRouter()
const { user } = useUser()
const checkoutIsEnabled = useFeatureToggle(FeatureToggles.Checkout)

const { locale, locales, setLocale } = useI18n()
const { updateLanguage } = createAccount()

const handleCommand = (item: string) => {
  updateLanguage(item, {
    onSuccess: () => {
      setLocale(item)
    },
  })
}

const handleSettingsCommand = (item: string) => {
  if (item === 'create-business') {
    router.push('/auth/business')
  } else if (item === 'developer') {
    router.push('/settings/webhooks')
  } else if (item === 'profile') {
    router.push('/settings/profile')
  } else if (item === 'invite-colleagues') {
    useSettingsModal().openNewTeam()
  } else if (item === 'team') {
    router.push('/settings/teams')
  } else if (item === 'support') {
    useSettingsModal().openSupport()
  } else if (item === 'generate-payment') {
    useTransactionsModal().openPayment()
  } else if (item === 'developer') {
    // target="_blank"
    navigateTo('https://maplerad.dev/', {
      external: true,
    })
  } else if (item === 'sign-out') {
    useUser().logOut()
  }
}

const { business, newGlobal, toggleMode } = useBusiness()
const isOpen = ref(false)
const toggleDropdown = () => (isOpen.value = !isOpen.value)
</script>

<style scoped>
.ml {
  margin-left: min(20%, 14.5rem);
}
.sub_link {
  @apply cursor-pointer flex items-center text-greyDark text-base font-medium;
}
.bsha {
  box-shadow: 0px 4px 16px rgba(31, 41, 55, 0.1);
}
.slide-enter-active,
.slide-leave-active {
  transition: all 0.2s ease;
}
.slide-enter-from,
.slide-leave-to {
  transform: translateY(-10px);
  opacity: 0;
}
</style>
